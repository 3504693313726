export default {
  tableColumnProps: [
    {
      label: 'page.setting.olSourceTab.name',
      type: null,
      columnKey: null,
      prop: 'name',
      width: null,
      minWidth: '100px',
      sortable: false,
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
  ],
};