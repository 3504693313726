<template>
  <gmap-info-window :position="position" :opened="opened" :options="options" @closeclick="opened=false" >    
    <div class="fms-infowindow-delete" v-on:click="deleteAction">
      Delete
    </div>
  </gmap-info-window>
</template>

<script>
import { CopyObject } from '@/utils/common.js';
import {logger} from "@/logger/index";
export default {
  name: 'DeleteDialog',
  data() {
    return {
      options: {
        disableAutoPan: true,
      },
      openIndex: null,
      latLng: null,
    };
  },
  computed: {
    opened: {
      get() {
        return this.openIndex != null || this.latLng != null;
      },
      set(value) {
        if (value === false) {
          this.close();
        }
      },
    },
    position() {
      if (this.openIndex == null && this.latLng == null) {
        return {
          lat: 0,
          lng: 0,
        };
      }

      if (this.openIndex != null) {
        return this.path[this.openIndex];
      }

      if (this.latLng != null) {
        return this.latLng;
      }
    },
  },
  methods: {
    open(index, latLng) {
      this.openIndex = index;
      this.latLng = latLng;
    },
    close() {
      this.openIndex = null;
      this.latLng = null;
      this.$emit('close');
    },
    deleteAction() {
      logger.debug('deleteAction');
      this.$emit('delete', this.openIndex, this.latLng);
      this.close();
    },
  },
  watch: {
  },
  mounted() {
  },
  props: {
    path: {
      required: false,
      type: Array,
    }
  },
}
</script>

<style lang="scss">
</style>

<style lang="scss">
.fms-infowindow-container.zone-editor {
  width: 275px;

  .footer {
    text-align: center;
  }

  .el-input-number__decrease, .el-input-number__increase {
    background-color: #d2d2d2;
  }

  .el-input--mini {
    font-size: 14px;
  }
}
// .gm-style-iw {
//    width: 350px !important;
//    top: 0 !important;
//    left: 0 !important;
//    border-radius: 2px 2px 0 0;
// }
</style>
