<template>
  <flight-map :operator-items="['map-mode', 'gps']"
              :load-items="['fence']"
              @map-click="mapClick"
              @operator="o => $emit('operator', o)"
  >
  <!-- flight-map use z-index 1 (tile-source) and z-index 2 (fence) -->
  <template v-for="(zone, index) in map.noFlyZone">
    <component :is="formatNoFlyZoneTypeToOlComponent(zone.type)"
                v-if="editZone == null || zone.id != editZone.id"
                :key="`Zones-NoFlyZone-${zone.id}`"
                :zone="zone"
                :z-index="3 + index"/>
  </template>

  <template v-if="editZone" >
    <polygon-editor 
                    :key="`PolygonEditor-${editZone.id}`"
                    :polygonComponent="formatNoFlyZoneTypeToOlComponent(editZone.type)"
                    :zone="editZone"
                    @update:zone="updateData"
                    zoneType="no_fly_zones"
                    :z-index="3 + noFlyZone.length"
    />
    <zone-dialog :value="editZone"
                @input="updateData"
                @close="e => this.$emit('zone-close')"
                @delete="e => this.$emit('zone-delete')"
                @save="e => this.$emit('zone-save')">
    </zone-dialog>
  </template>

  </flight-map>
</template>

<script>
import { CopyObject } from '@/utils/common.js';

import { GeographicApiToOl, GeographicOlToApi, BoundsOlToApi, PolygonsApiToOl } from '@/utils/map.js';
import { formatNoFlyZoneTypeToOlComponent } from '@/utils/map.js';
import FlightMap from '@/components/OpenLayers/FlightMap.vue';
import PolygonEditor from '@/components/OpenLayers/PolygonEditor.vue';
import FlyArea from '@/components/OpenLayers/FlyArea.vue';
import ZoneDialog from '@/components/OpenLayers/ZoneDialog.vue';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'NoFLyZoneMap-OpenLayers',
  components: {
    FlightMap,
    PolygonEditor,
    FlyArea,
    ZoneDialog,
  },
  data() {
    return {
      map: {
        editZone: null,
      },
      lastMapRange: [],
    };
  },
  computed: {
    ...mapGetters({
      editZone: 'noFlyZone/getNoFlyZoneOl',
      mapBoundsInteger: 'map/boundsInteger',
    }),
  },
  props: {
    noFlyZone: {
      type: Array,
    },
    mapRange: {
      type: Array,
    },
  },
  watch: {
    mapBoundsInteger(now, old) {
      if (_.isEqual(now, old)) {
        return;
      }

      this.$emit('update:mapRange', now);
    },
    noFlyZone() {
      this.loadNoFlyZoneFromParent();
    },
  },
  mounted() {
    this.loadNoFlyZoneFromParent();
  },
  methods: {
    mapClick(event, featureAndLayerList) {
      let clickEvent = {
        position: GeographicOlToApi(event.coordinate),
      };

      let onNoFlyZoneMiddleFeature = featureAndLayerList.find(featureAndLayer => {
        return featureAndLayer.properties.type === 'middlePoint'
          && featureAndLayer.properties.source === 'no_fly_zones';
      })
      if (onNoFlyZoneMiddleFeature) {
        clickEvent = {
          ...clickEvent,
          ...onNoFlyZoneMiddleFeature.properties,
        };
        this.insertPoint(clickEvent);
        // this.$emit('map-click', clickEvent);
        return;
      }

      let onNoFlyZoneFeature = featureAndLayerList.find(featureAndLayer => {
        return featureAndLayer.properties.type === 'polygon'
          && featureAndLayer.properties.source === 'no_fly_zones';
      })
      if (onNoFlyZoneFeature) {
        clickEvent = {
          ...clickEvent,
          ...onNoFlyZoneFeature.properties,
        };
        this.$emit('map-click', clickEvent);
        return;
      }

      this.$emit('map-click', clickEvent);
    },
    loadNoFlyZoneFromParent() {
      let zoneData = CopyObject(this.noFlyZone);
      this.$set(this.map, 'noFlyZone', zoneData.map((zone) => {
        zone.coordinates = PolygonsApiToOl(zone.polygons)
        delete zone.polygons;
        return zone;
      }));
    },
    formatNoFlyZoneTypeToOlComponent,
    ...mapActions({
      updateData: 'noFlyZone/loadFromOl',
      insertPoint: 'noFlyZone/insert',
    })
  },
}
</script>