<template>
  <admin-fillall-layout>
    <tab-content
      :tab-list="tabList"
      default-tab="location"
    />
  </admin-fillall-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TabContent from '@/components/TabContent.vue';
import Location from './Location.vue';
import About from './About.vue';
import NoFlyZone from './NoFlyZone.vue';
import User from './User.vue';
import Accounting from './Accounting.vue';
import OlSource from './OlSource.vue';

export default {
  name: 'SettingHome',
  components: {
    TabContent,
    Location,
    About,
    NoFlyZone,
    User,
    Accounting,
    OlSource,
  },
  computed: {
    ...mapGetters({
      mapEngine: 'user/mapEngine',
      olSourceEdit: 'user/olSourceEdit',
      isAdmin: 'user/isAdmin',
    }),
    tabList() {
      const item = [
        // {
        //   label: 'page.setting.tab.domain',
        //   name: 'domain',
        // },
        {
          label: 'page.setting.tab.user',
          name: 'user',
          component: User,
        },
        {
          label: 'page.setting.tab.location',
          name: 'location',
          component: Location,
        },
        {
          label: 'page.setting.tab.noFlyZone',
          name: 'noFlyZone',
          component: NoFlyZone,
        },
        {
          label: 'page.setting.tab.accounting',
          name: 'accounting',
          component: Accounting,
        },
        {
          label: 'page.setting.tab.about',
          name: 'about',
          component: About,
        },
      ];
      if (this.isAdmin && this.mapEngine === 'OpenLayers' && this.olSourceEdit) {
        item.splice(-1, 0, {
          label: 'page.setting.tab.olSource',
          name: 'olSource',
          component: OlSource,
        });
      }
      return item;
    },
  },
  beforeRouteEnter(to, from, next) {
    // 判斷網址來源，如果跟 mission 無關那就留下 params 告訴 created 要清掉現有任務
    const pathFirstLevel = from.path.split('/')[1];
    if (pathFirstLevel.toLocaleLowerCase() !== 'setting') {
      to.params.clear = true;
    } else {
      to.params.clear = false;
    }
    next();
  },
  data() {
    return {};
  },
};
</script>
