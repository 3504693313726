<template>
  <vl-overlay v-if="zone.type" :position="editZonePosition" :auto-pan="true" :auto-pan-animation="{ duration: 300 }" positioning="top-center">
    <div class="fms-overlay-container">
      <div class="zone-editor">
        <h2>{{ $t(`map.${zone.type}`) }}</h2>

        <el-form :model="zone">
          <el-form-item label="Name">
            <el-input size="mini" v-model="zone.name"></el-input>
          </el-form-item>

          <el-form-item label="Altitude" v-if="zone.type == 'altitude_limit'">
            <el-input-number size="mini" v-model="zone.max_altitude"></el-input-number>
          </el-form-item>

          <el-form-item label="Altitude" v-if="zone.type == 'obstacle'">
            <el-input-number size="mini" v-model="zone.min_altitude"></el-input-number>
          </el-form-item>

        </el-form>
        <section class="footer">
          <el-button size="mini" type="warning" @click="$emit('delete')"  v-show="zone.id">{{ $t('button.delete') }}</el-button>
          <el-button size="mini" type="info" @click="$emit('close')">{{ $t('button.cancel') }}</el-button>
          <el-button size="mini" type="primary" @click="$emit('save')">{{ $t('button.save') }}</el-button>  
        </section>
      </div>

    </div>
  </vl-overlay>
</template>

<script>
import { CopyObject } from '@/utils/common.js';
import { findPointOnSurface } from 'vuelayers/lib/ol-ext';
import { GeographicApiToOl, GeographicOlToApi, PolygonsApiToOl } from '@/utils/map.js';
import _ from 'lodash';

import { boundingExtent } from 'ol/extent';

export default {
  name: 'ZoneDialog',
  data() {
    return {
      options: {
        disableAutoPan: true,
      },
      zone: {},
      zoneLayer: null,
    };
  },
  computed: {
    editZonePosition() {
      if (this.value == null || this.value.coordinates == null) {
        return;
      }
      // let coordnates = PolygonsApiToOl(this.value.polygons);
      let extentBox = boundingExtent(this.value.coordinates[0]);

      return [(extentBox[0] + extentBox[2]) / 2, (extentBox[3])];
    },
  },
  methods: {
  },
  watch: {
    value: {
      handler(zone) {
        if (zone) {
          this.$set(this, 'zone', CopyObject(zone));
        } else {
          this.zone = {};
        }
      },
      deep: true,
    },
    zone: {
      handler(zone, original) {
        if (original == null || original.name === undefined) {
          return;
        }
        if (_.isEqual(zone, this.value)) {
          return;
        }
        this.$emit('input', this.zone);
      },
      deep: true,
    },
  },
  mounted() {
    if (this.value) {
      this.$set(this, 'zone', CopyObject(this.value));
    }
  },
  props: {
    value: {
      required: true,
    },
  },
}
</script>

<style lang="scss">
.fms-overlay-container.zone-editor {
  width: 275px;

  .footer {
    text-align: center;
  }

  .el-input-number__decrease, .el-input-number__increase {
    background-color: #d2d2d2;
  }

  .el-input--mini {
    font-size: 14px;
  }
}

</style>