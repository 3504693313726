<template>
  <gmap-info-window
    v-if="zone.type"
    :position="position"
    :opened="opened"
    :options="options"
    @closeclick="opened=false"
  >
    <div class="fms-infowindow-container zone-editor">
      <h2>{{ $t(`map.${zone.type}`) }}</h2>

      <el-form :model="zone">
        <el-form-item :label="$t('page.mission.name')">
          <el-input
            v-model="zone.name"
            size="mini"
            :disabled="!editable"
          />
        </el-form-item>

        <el-form-item
          v-if="zone.type == 'altitude_limit'"
          :label="$t('map.altitude')"
        >
          <el-input-number
            v-model="zone.max_altitude"
            :disabled="!editable"
            size="mini"
          />
        </el-form-item>

        <el-form-item
          v-if="zone.type == 'obstacle'"
          :label="$t('map.altitude')"
        >
          <el-input-number
            v-model="zone.min_altitude"
            :disabled="!editable"
            size="mini"
          />
        </el-form-item>
      </el-form>
      <section class="footer">
        <el-button
          v-show="zone.id"
          :disabled="!isAdmin||!editable"
          size="mini"
          type="warning"
          @click="$emit('delete')"
        >
          {{ $t('button.delete') }}
        </el-button>
        <el-button
          size="mini"
          type="info"
          @click="opened=false"
        >
          {{ $t('button.cancel') }}
        </el-button>
        <el-button
          :disabled="!isAdmin||!editable||zone.polygons[0].length <= 2"
          size="mini"
          type="primary"
          @click="save"
        >
          {{ $t('button.save') }}
        </el-button>
      </section>
    </div>
  </gmap-info-window>
</template>

<script>
import { mapGetters } from 'vuex';
import { CopyObject } from '@/utils/common';

export default {
  name: 'ZoneDialog',
  props: {
    value: {
      required: true,
      type: Object,
    },
    editable: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      options: {
        disableAutoPan: true,
        pixelOffset: {
          width: 0,
          height: -10,
        },
      },
      zone: {},
      position: null,
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
    }),
    opened: {
      get() {
        return this.value != null;
      },
      set(value) {
        if (value === false) {
          this.$emit('close');
        }
      },
    },
  },
  watch: {
    value: {
      handler(zone) {
        if (zone) {
          const { name } = zone;
          this.$set(this, 'zone', CopyObject(zone));
          this.zone.name = name;
          this.getPosition();
        } else {
          this.zone = {};
        }
      },
      deep: true,
    },
    zone: {
      handler() {
        this.$emit('input', this.zone);
      },
      deep: true,
    },
  },
  mounted() {
    if (this.value) {
      this.$set(this, 'zone', CopyObject(this.value));
      this.getPosition();
    }
  },
  methods: {
    save() {
      this.$emit('before-input');
      this.zone.polygons = this.value.polygons;
      this.$emit('save', this.zone);
    },
    getPosition() {
      if (this.value == null) {
        this.position = {
          lat: 0,
          lng: 0,
        };
      }
      this.$gmapApiPromiseLazy().then(() => {
        const bounds = new google.maps.LatLngBounds();
        const path = this.value.polygons.forEach((polygon) => {
          polygon.forEach((latLng) => {
            bounds.extend(latLng);
          });
        });

        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();
        this.position = {
          lat: northEast.lat(),
          lng: (northEast.lng() + southWest.lng()) / 2,
        };
      });
    },
  },
};
</script>

<style lang="scss">
</style>

<style lang="scss">
.fms-infowindow-container.zone-editor {
  width: 275px;

  .footer {
    text-align: center;
  }

  .el-input--mini {
    font-size: 14px;
  }
}
// .gm-style-iw {
//    width: 350px !important;
//    top: 0 !important;
//    left: 0 !important;
//    border-radius: 2px 2px 0 0;
// }
</style>
