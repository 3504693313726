export default {
  tableColumnProps: [
    {
      label: 'page.setting.locationTab.map',
      prop: 'thumbnail',
      width: '144px',
      minWidth: null,
      sortable: null,
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.setting.locationTab.name',
      type: null,
      columnKey: null,
      prop: 'name',
      width: null,
      minWidth: '100px',
      sortable: 'custom',
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.setting.locationTab.description',
      type: null,
      columnKey: null,
      prop: 'comment',
      width: null,
      minWidth: '170px',
      sortable: 'custom',
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.setting.locationTab.modified',
      type: null,
      columnKey: null,
      prop: 'updater_name',
      width: null,
      minWidth: '130px',
      sortable: null,
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.setting.locationTab.altitude',
      type: null,
      columnKey: null,
      prop: 'altitude',
      width: null,
      minWidth: '160px',
      sortable: null,
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
  ],
};