<template>
  <admin-fillall-layout>
    <div class="tab-content">
      <div class="tabs-container">
        <div
          v-for="tab in tabList"
          :key="tab.name"
          class="tabs-wrapper"
          @click="alterTabs(tab.name)"
        >
          <div class="tabs-detail">
            {{ $t(tab.label) }}
          </div>
          <div
            v-if="activeTabName === tab.name"
            class="active-bar"
          />
        </div>
      </div>
      <section class="tab-content__content">
        <component
          :is="activeTab.component"
          v-if="activeTab"
        />
      </section>
    </div>
  </admin-fillall-layout>
</template>

<script>
export default {
  name: 'TabContent',
  props: {
    tabList: {
      required: true,
      type: [Array],
    },
    defaultTab: {
      required: false,
      type: [String],
    },
  },
  data() {
    return {
      activeTabName: '',
    };
  },
  computed: {
    activeTab() {
      return this.tabList.find((tab) => tab.name === this.activeTabName);
    },
  },
  mounted() {
    if (this.$route.query.tab != null) {
      this.activeTabName = this.$route.query.tab;
    } else {
      this.activeTabName = this.defaultTab;
    }
  },
  methods: {
    alterTabs(tab) {
      this.activeTabName = tab;
    },
  },
};
</script>

<style lang="scss">

.tab-content {
  display: flex;
  flex-flow: column;
  height: 100%;

  .tabs-container {
    display: flex;
    width: 80%;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 auto;
    border-bottom: 1px solid #e5e5e5;
    font-size: 16px;
    font-weight: 500;
    color: rgba(60, 60, 60, 0.9);
    height: 50px;
    max-width: 1350px;
    .tabs-wrapper {
      height: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2px;
      cursor: pointer;
    }
    .tabs-detail {
      height: 48px;
      margin-top: 19px;
    }
    .active-bar {
      border: 2px solid #ffc900;
      width: 100%;
    }
  }

  .tab-content__content {
    flex-grow: 1;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .sub-app {
    padding-top: 35px;
    padding-left: 89px;
    padding-right: 89px;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1528px;

    .app-main-toolbar {
      flex-grow: 0;
      .el-row {
        h1 {
          margin-top: 0;
        }
      }
    }

    .app-main-content {
      flex-grow: 1;
      position: relative;
    }

  }
}
</style>
