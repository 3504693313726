<template>
  <div class="sub-app">
    <section class="app-main-toolbar">
      <el-row class="no-margin">
        <h1>{{$t('page.setting.olSourceTab.title')}}</h1>
      </el-row>
      <el-row>
        <p>{{ $t('page.setting.olSourceTab.warning') }}</p>
      </el-row>
      <el-row type="flex" justify="end">
        <copy-url :url="JSON.stringify(olSourceList)" title="Configuration">
          <el-button type="primary"
                     class="main-actions"
          >{{ $t('button.copy') }}</el-button>
        </copy-url>
        &nbsp;
        <el-button type="primary"
                   class="main-actions"
                   @click="dialogOpen()"
        ><fa-icon icon="plus"/> {{ $t('button.add') }}</el-button>
      </el-row>
    </section>

    <section class="app-main-content">
      <data-table :data="olSourceList"
                :total="olSourceList.length"
                :loading="page.loading"
                :config="page.tableProps"
                @query-change="loadData"
                :showToolBar="false"
      >
        <el-table-column v-for="columnProp in page.tableProps.tableColumnProps"
                        :key="columnProp.prop"
                        :type="columnProp.type"
                        :label="$t(columnProp.label)"
                        :column-key="columnProp.columnKey"
                        :prop="columnProp.prop"
                        :width="columnProp.width"
                        :min-width="columnProp.minWidth"
                        :sortable="columnProp.sortable"
                        :resizable="columnProp.resizable"
                        :formatter="columnProp.formatter"
                        :align="columnProp.align"
                        :header-align="columnProp.headerAlign"
                        :class-name="columnProp.className"
                        :label-class-name="columnProp.labelClassName"
        >
          <template scope="scope">
            <div>
              {{ scope.row[columnProp.prop] }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label=""
                          fixed="right"
                          width="55px"
        >
          <template scope="scope">
            <!-- <el-button-group> -->
              <fa-button size="mini"
                          :no-border="true"
                          icon=""
                          class="action-button edit"
                          @click="dialogOpen(scope.$index)"
              />
            <!-- </el-button-group> -->
          </template>
        </el-table-column>
      </data-table>
    </section>

    <!-- edit dialog -->
    <default-dialog :show-dialog="olSourceEditShow"
                    :title="(olSourceEditMode == 'add') ? $t('page.setting.olSourceTab.dialog.addTitle') : $t('page.setting.olSourceTab.dialog.updateTitle')"
                    :next-button-text="$t('page.setting.olSourceTab.dialog.preview')"
                    @close="dialogClose"
                    @next="dialogNext"
                    :footer-align="(olSourceEditMode == 'edit') ? 'right' : 'center'">>
      <template slot="main">
        <el-form ref="form" v-if="form != null" :model="form" label-width="160px">
          <el-form-item :label="$t('page.setting.olSourceTab.name')">
            <el-input v-model="form.name"></el-input>
          </el-form-item>

          <el-form-item :label="$t('page.setting.olSourceTab.dialog.type')">
            <el-select :value="form.component" @input="selectType" :placeholder="$t('page.setting.olSourceTab.dialog.typeSelect')">
              <el-option v-for="type in olSourceTypes"
                         :key="type.component"
                         :label="type.name"
                         :value="type.component"
              ></el-option>
            </el-select>
          </el-form-item>

          <template v-if="form.component != null">
            <h3>{{ $t('page.setting.olSourceTab.dialog.options') }}</h3>
            <p>{{ $t('page.setting.olSourceTab.dialog.olRefs') }}<a :href="componentOptions.document" target="_blank">ol/source/{{ componentOptions.name }} </a></p>
            <el-form-item v-for="option of componentOptions.options" :key="option.key" :label="option.key">
              <template v-if="option.required">
                <el-input v-model="form[option.key]"></el-input>
              </template>
              <template v-else>
                <el-row>
                  <el-col class="no-padding" :span="4">
                    <el-switch class="coretronic"
                               :value="form[option.key] != null"
                               @input="value => toggleOption(option.key, value)"
                    ></el-switch>
                  </el-col>
                  <el-col class="no-padding" :span="18" v-if="form[option.key] != null">
                    <el-input v-model="form[option.key]"></el-input>
                  </el-col>
                </el-row>
              </template>

            </el-form-item>
          </template>
        </el-form>
      </template>
      <template slot="footer">
        <el-button :disabled="!isAdmin" class="main-actions right-button" type="warning" @click="dialogDelete" v-if="(olSourceEditMode == 'edit')" >{{ $t('button.delete') }}</el-button>
      </template>
    </default-dialog>

  </div>
</template>

<script>

import DefaultDialog from '@/components/DefaultDialog.vue';
import tableProps from '@/config/tableProps/olSourceList.js';
import olSourceTypes from '@/config/tableProps/olSourceType.js';
import DataTable from "@/components/DataTable/DataTable";
import FaButton from '@/components/FaButton';
import CopyUrl from '@/components/CopyUrl.vue';
import { CopyObject } from '@/utils/common.js';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'OlSourceSubApp',
  components: {
    DataTable,
    DefaultDialog,
    FaButton,
    CopyUrl,
  },
  data() {
    return {
      deleteShow: false,
      olSourceTypes,
      form: null,
      componentOptions: null,
      page: {
        loading: false,
        tableProps,
      },
    };
  },
  computed: {
    ...mapGetters({
      isAdmin : "user/isAdmin",
      olSourceList: 'user/olSource',
      olSourceEditShow: 'olSource/shouldShow',
      olSourceEditMode: 'olSource/getMode',
      olSourceEditData: 'olSource/getData',
    }),
  },
  mounted() {
    this.readForm();
  },
  methods: {
    loadData() {},
    dialogOpen(index) {
      if (index != null) {
        this.setEditMode('edit');
        let form = { ...this.olSourceList[index] };
        form.index = index;
        this.setEditData(form);
      } else {
        this.setEditMode('add');
      }
      this.readForm();
    },
    readForm() {
      this.$nextTick(() => {
        this.form = CopyObject(this.olSourceEditData);
        this.componentOptions = this.findOption(this.form.component);
      });
    },
    dialogClose() {
      this.setEditMode(null);
    },
    dialogDelete() {
      let newSourceList = this.olSourceList.slice();
      newSourceList.splice(this.form.index, 1);
      this.setOlTileSource(newSourceList);
      this.setEditMode(null);
      this.form = null;
    },
    dialogNext() {
      let sourceItem = CopyObject(this.form);
      let error = false;
      for (var key in sourceItem) {
        let option = this.componentOptions.options.find(o => o.key == key);
        if (option == null) {
          continue;
        }

        let value = sourceItem[key];
        if (option.required && (value == null || value == '')) {
          this.$showFail(
            this.$t('page.setting.olSourceTab.dialog.noField', { type: option.type, key })
          );
          error = true;
          continue;
        }

        if (value == null || value == '') {
          delete sourceItem[key];
          continue;
        }

        switch (option.type) {
          case 'number':
            try {
              value = Number(value);
            } catch (error) {
              this.$showFail(
                this.$t('page.setting.olSourceTab.dialog.parseFail', { type: option.type, key, error })
              );
              error = true;
            }
            continue;
          case 'object':
          case 'array':
            try {
              value = JSON.parse(value);
            } catch (error) {
              this.$showFail(
                this.$t('page.setting.olSourceTab.dialog.parseFail', { type: option.type, key, error })
              );
              error = true;
            }
            continue;
        }
      }

      if (error) {
        return;
      }

      this.setEditData(sourceItem);

      let queryParams = {
        return: this.$router.currentRoute.name,
        returnQuery: JSON.stringify(this.$router.currentRoute.query),
      };

      this.$router.push({
        name: 'olSourcePreview',
        query: queryParams,
      });

    },
    selectType(type, args2, args3) {
      let name = this.form.name;
      this.$set(this, 'form', {
        name,
        component: type,
      });
      this.componentOptions = this.findOption(type);
      for (let option of this.componentOptions.options) {
        if (option.required) {
          this.form[option.key] = '';
        }
      }
    },
    toggleOption(key, value) {
      if (value) {
        this.$set(this.form, key, '');
      } else {
        this.$delete(this.form, key)
      }
    },
    findOption(component) {
      return olSourceTypes.find(t => t.component === component);
    },
    ...mapActions({
      setEditMode: 'olSource/setEditMode',
      setEditData: 'olSource/setEditData',
      setOlTileSource: 'user/setOlTileSource',
    }),
  },
}
</script>