<template>
  <div class="sub-app location">
    <section class="app-user-toolbar">
      <el-row class="no-margin">
        <h1> {{ $t('page.setting.userTab.title') }}</h1>
      </el-row>
      <el-row
        class="section-button-row"
        type="flex"
        align="bottom"
        justify="end"
      >
        <el-button
          type="primary"
          :disabled="!isAdmin"
          class="main-actions"
          @click="dialogOpen()"
        >
          <fa-icon icon="plus" /> {{ $t('button.add') }}
        </el-button>
      </el-row>
    </section>
    <section class="app-main-content">
      <div
        v-if="page.loading == false && source.items.length <= 0"
        class="no-mission"
      >
        <div class="no-mission__content">
          <h3>No User yet</h3>
          <p>Please click the "Add New" button to add a new user.</p>
          <el-button
            type="primary"
            :disabled="!isAdmin"
            class="main-actions"
            @click="dialogOpen()"
          >
            <fa-icon icon="plus" /> {{ $t('button.add') }}
          </el-button>
        </div>
      </div>
      <el-row
        class="section-label-row"
        type="flex"
        align="bottom"
        justify="start"
      >
        <div class="section-label">
          {{ $t('page.setting.userTab.allUsers') }} ({{ source.items.length }})
        </div>
      </el-row>
      <div
        v-if="source.items.length > 0"
        class="drones-selection"
      >
        <div class="drones">
          <div
            v-for="(drone, index) in source.items"
            :key="`drone-${index}`"
            :class="`drone`"
          >
            <img
              class="thumbnail"
              src="/asserts/icon/userprofile-icon.svg"
              @click="dialogOpen(drone.id)"
            >
            <span @click="dialogOpen(drone.id)">{{ drone.name }}</span>
            <span
              class="button"
              @click="dialogOpen(drone.id)"
            >{{ $t('button.edit') }}</span>
            <img
              class="select"
              src="/asserts/icon/loadmission-selected-icon.svg"
            >
          </div>
        </div>
      </div>

      <default-dialog
        :title="(accountMode == 'add') ? $t('page.setting.userTab.addTitle') : $t('page.setting.userTab.updateTitle')"
        :show-dialog="(accountShow)"
        :next-button-text="$t('button.save')"
        @close="dialogClose"
        @next="dialogSave"
      >
        <template slot="main">
          <el-form
            ref="form"
            :model="drone"
            label-width="120px"
          >
            <el-form-item
              label=""
              class="icon"
            >
              <el-input
                v-model="drone.name"
                class="device-name-align-center"
              />
            </el-form-item>
            <hr>

            <el-form-item :label="$t('page.setting.userTab.dialog.account')">
              <el-input
                v-model="drone.account"
                :disabled="accountMode != 'add'"
              />
            </el-form-item>
            <el-form-item
              v-if="(accountMode == 'add')"
              :label="$t('page.setting.userTab.dialog.password')"
            >
              <el-input
                v-model="drone.password"
                type="password"
              />
            </el-form-item>

            <el-form-item :label="$t('page.setting.userTab.dialog.permission')">
              <label style="color: rgba(128, 128, 128, 0.9);">{{ $t('page.setting.userTab.dialog.enable') }}
              </label>
            </el-form-item>

            <el-form-item class="whiteBody">
              <div style="margin-left: 38px;">
                <Label>{{ $t('page.setting.userTab.dialog.domain') }}</Label>
                <BR />
                <el-checkbox v-model="domainRead">
                  {{ $t('page.setting.userTab.dialog.read') }}
                </el-checkbox>
                <el-checkbox v-model="domainWrite">
                  {{ $t('page.setting.userTab.dialog.write') }}
                </el-checkbox>
              </div>
            </el-form-item>

            <el-form-item class="whiteBody">
              <div style="margin-left: 38px;">
                <Label style="">{{ $t('page.setting.userTab.dialog.groups') }}</Label>
                <BR />
                <el-checkbox v-model="locationRead">
                  {{ $t('page.setting.userTab.dialog.read') }}
                </el-checkbox>
                <el-checkbox v-model="locationWrite">
                  {{ $t('page.setting.userTab.dialog.write') }}
                </el-checkbox>
              </div>
            </el-form-item>

            <el-form-item
              v-if="(accountMode == 'update' && 1==0)"
              :label="$t('page.setting.userTab.dialog.groups')"
            >
              <div>
                <el-checkbox-group v-model="drone.groups">
                  <el-checkbox-button
                    v-for="group in drone.groups"
                    :key="group.id"
                    disabled
                    :label="group.name"
                  >
                    {{ group.name }}
                  </el-checkbox-button>
                </el-checkbox-group>
              </div>
            </el-form-item>
          </el-form>
        </template>

        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            v-if="(accountMode == 'update')"
            :disabled="!isAdmin"
            class="main-actions right-button"
            type="warning"
            @click="deleteShow = true"
          >{{ $t('button.delete') }}</el-button>
        </span>
      </default-dialog>

      <el-dialog
        class="delete"
        :visible.sync="(deleteShow)"
        width="651px"
      >
        <div class="content">
          <h2>{{ $t('page.setting.deleteConfirm.title') }}</h2>
          <p>{{ $t('page.setting.deleteConfirm.userBody') }}</p>
        </div>

        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            class="main-actions"
            @click="deleteClose"
          >{{ $t('button.cancel') }}</el-button>
          <el-button
            class="main-actions"
            type="primary"
            @click="deleteAction"
          >{{ $t('page.setting.delete.user') }}</el-button>
        </span>
      </el-dialog>
    </section>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import userApi from '@/services/api/domain/user';
import DefaultDialog from '@/components/DefaultDialog.vue';
import { CopyObject } from '@/utils/common.js';

export default {
  components: {
    DefaultDialog,
  },
  computed: {
    ...mapGetters({
      groupId: 'user/groupId',
      accountMode: 'account/getMode',
      accountData: 'account/getAccount',
      accountShow: 'account/shouldShow',
      authUser: 'user/user',
      group: 'user/group',
      isAdmin: 'user/isAdmin',
    }),
    droneGroupCount() {
      return Math.floor(this.source.items.length / 5) + 1;
    },
  },

  data() {
    return {
      // googleMapKey: config.googleMapKey,
      deleteShow: false,
      droneId: null,
      source: {
        items: [],
        paging: {
          total: 0,
        },
      },
      filter: {},
      page: {
        loading: false,
      },
      drone: CopyObject(this.$store.state.account.account),
      domainRead: false,
      domainWrite: false,
      locationRead: false,
      locationWrite: false,
    };
  },
  methods: {
    dialogClose() {
      // confirm data will lost?
      // this.dialog.show = false;
      this.setAccountMode(null);
      this.drone = CopyObject(this.$store.state.account.account);
      this.resetCheckboxes();
    },

    validEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    async dialogSave() {
      this.errors = [];

      if (!this.drone.name) {
        this.errors.push('Name required.');
      }
      if (!this.drone.account && this.accountMode === 'add') {
        this.errors.push('Account required.');
      } else if (!this.validEmail(this.drone.account) && this.accountMode === 'add') {
        this.errors.push('Valid email required.');
      }
      if (!this.drone.password && this.accountMode === 'add') {
        this.errors.push('Password required.');
      }

      let p = 0;
      if (this.domainWrite) p += 128;
      if (this.domainRead) p += 64;
      if (this.locationWrite) p += 32;
      if (this.locationRead) p += 16;
      this.drone.permission = p;

      if (!this.drone.permission) {
        this.errors.push('Permission required.');
      }

      if (this.errors.length) {
        this.$showFail(this.errors[0]);
        return true;
      }
      this.setAccount(this.drone);
      try {
        if (this.accountMode === 'add') {
          const status = await userApi.create(this.drone);
        } else {
          const status = await userApi.update(this.drone.id, this.drone);
        }
        this.$showSuccess(status.msg);
      } catch (error) {
        this.$showFail(error);
      }
      this.setAccountMode(null);
      this.drone = CopyObject(this.$store.state.account.account);
      this.loadData();
      // if (this.accountMode === "add") {
      //   this.$router.push({
      //     name: 'profile',
      //   });
      // } else {
      //   this.$router.push({
      //     name: 'profile',
      //   });
      // }
    },

    deleteClose() {
      this.deleteShow = false;
    },

    async deleteAction() {
      try {
        this.deleteShow = false;
        this.dialogClose();
        this.page.loading = true;

        const status = await userApi.delete(this.droneId);
        this.$showSuccess(status.msg);
      } catch (error) {
        this.$showFail(error);
      }

      this.loadData();
    },

    ...mapActions({
      setAccountMode: 'account/setMode',
      setAccount: 'account/setAccount',
    }),

    async loadData() {
      try {
        this.page.loading = true;
        const { meta, data } = await userApi.getList();
        this.source.items = data.users;
      } catch (error) {
        this.$showFail(error);
      } finally {
        this.page.loading = false;
      }
      this.resetCheckboxes();
    },

    resetCheckboxes() {
      this.domainRead = false;
      this.domainWrite = false;
      this.locationRead = false;
      this.locationWrite = false;
    },

    permissionCheckBoxes(p) {
      if ((p / 128) >= 1) { this.domainWrite = true; }
      if (((p % 128) / 64) >= 1) { this.domainRead = true; }
      if ((((p % 128) % 64) / 32) >= 1) { this.locationWrite = true; }
      if (((((p % 128) % 64) % 32) / 16) >= 1) { this.locationRead = true; }
    },

    async dialogOpen(droneId) {
      if (droneId) {
        this.droneId = droneId;
        try {
          this.page.loading = true;
          const { data } = await userApi.get(droneId);
          this.setAccount(data);
          this.drone = CopyObject(this.$store.state.account.account);
          this.setAccountMode('update');
          this.permissionCheckBoxes(this.drone.permission);
        } catch (error) {
          this.$showFail(error);
        } finally {
          this.page.loading = false;
        }
      } else {
        this.setAccountMode('add');
      }
    },

  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss">

.no-mission {

  height: 461px;
  border-style: dashed;
  border-color: #e1e1e1;
  border-radius: 13px;
  text-align: center;

  .no-mission__content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
  }

  h3 {
    color: #a5a5a5;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 22px;
  }

  p {
    color: rgba(165, 165, 165, 0.9);
  }

  button {
    margin-top: 66px;
    margin-bottom: 66px;
  }

}

.drones-selection {
  background-color: rgba(216, 216, 216, 0.3);
  overflow-x: hidden;
  overflow-y: auto;
  // overflow: auto;
  .drones {
    margin-top: 50px;
    margin-left: 38px;
    margin-right: 39px;
    // margin-right: 40px;
    .drone {
      cursor: pointer;
      float: left;
      width: 111px;
      height: 143px;
      border-radius: 4px;
      text-align: center;
      position: relative;
      margin-left :15px;
      margin-right : 15px;
      span {
        display: block;
        margin-top: 4px;
        // font-family: HelveticaNeue;
        font-size: 12px;
        color: var(--greyish-brown-90);
      }
      img.thumbnail {
        width: 78px;
        height: 78px;
      }
      img.select {
        display: none;
      }
      span.button {
        border-radius: 8.5px;
        border: solid 1px #979797;
        width: 40px;
        height: 15px;
        // font-family: HelveticaNeue;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #979797;
        text-align: center;
        margin-left: 34px;
        margin-top: 6px;
      }
      span.button:hover{
        color: #58bd29;
        border: solid 1px #58bd29;
      }
    }

    .drone:first-child:last-child {
      float: left;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.right-button {
  float: left;
}

.app-user-toolbar {
  text-align: center;
}

.device-name-align-center{
  margin-top:23px;
}

</style>
