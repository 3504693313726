<template>
  <div>
    <component
      :is="polygonComponent"
      ref="polygon"
      :path="path"
      @click="polygonClick"
    />
    <template v-if="!readonly">
      <delete-dialog
        ref="deleteDialog"
        :path="path"
        @close="selectedPoint = null"
        @delete="waypointDelete"
      />
      <template
        v-for="(waypoint, index) in path"
      >
        <gmap-marker
          :key="`pathWaypont${index}`"
          :position="waypoint"
          :icon="(selectedPoint == index) ? markerSelectIcon : markerIcon"
          :draggable="!readonly"
          @dragstart="waypointDragstart"
          @drag="(event) => waypointDrag(event, index)"
          @dragend="(event) => waypointDragend(event, index)"
          @click="(event) => waypointClick(event, index)"
        />
        <gmap-marker
          v-if="middlePath[index]"
          :key="`pathMiddleWaypont${index}`"
          :position="middlePath[index]"
          :icon="WaypointStyle.middleWaypoint"
          @click="(event) => middleWaypointClick(event, index)"
        />
      </template>
    </template>
  </div>
</template>

<script>
import FlyArea from '@/components/GoogleMaps/FlyArea.js';
import NoFlyZone from '@/components/GoogleMaps/NoFlyZone.js';
import ObstacleZone from '@/components/GoogleMaps/ObstacleZone.js';
import AltitudeLimitZone from '@/components/GoogleMaps/AltitudeLimitZone.js';
import SurveyZone from '@/components/GoogleMaps/SurveyZone.js';
import { WaypointStyle } from '@/components/GoogleMaps/MarkerStyle.js';
import { CopyObject } from '@/utils/common.js';
import DeleteDialog from '@/components/Maps/DeleteDialog.vue';

export default {
  name: 'PolygonEditor',
  components: {
    FlyArea,
    NoFlyZone,
    ObstacleZone,
    AltitudeLimitZone,
    SurveyZone,
    DeleteDialog,
  },
  props: {
    value: {
      required: true,
      type: Array,
    },
    polygonComponent: {
      type: Object,
      required: true,
    },
    markerIcon: {
      type: Object,
      default() {
        return WaypointStyle.fenceWaypoint;
      },
    },
    markerSelectIcon: {
      type: Object,
      default() {
        return WaypointStyle.fenceWaypoint;
      },
    },
    index: {
      required: false,
    },
    // no marker, no middle marker, just polyline
    readonly: {
      type: Boolean,
      default: false,
    },
    // only disable edit function
    editable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      path: null,
      WaypointStyle,
      selectedPoint: null,
    };
  },
  computed: {
    middlePath() {
      const middlePath = [];
      for (const index in this.path) {
        const path = this.path[index];
        let nextPath = this.path[parseInt(index) + 1];

        if (nextPath == null) {
          if (this.path.length == 1) {
            break;
          }
          nextPath = this.path[0];
        }

        middlePath.push({
          lat: (path.lat + nextPath.lat) / 2,
          lng: (path.lng + nextPath.lng) / 2,
        });
      }
      return middlePath;
    },
  },
  watch: {
    value(newPath) {
      if (newPath && newPath.length > 0) {
        if (this.$refs.polygon && this.$refs.polygon.$polygonObject) {
          this.$refs.polygon.$polygonObject.setMap(this.$refs.polygon.$map);
        }
        this.$set(this, 'path', CopyObject(newPath));
      } else {
        if (this.$refs.polygon && this.$refs.polygon.$polygonObject) {
          this.$refs.polygon.$polygonObject.setMap(null);
        }
        this.path = null;
      }
    },
  },
  mounted() {
    if (this.value && this.value.length > 0) {
      this.$set(this, 'path', CopyObject(this.value));
    }
  },
  methods: {
    polygonClick(event) {
      this.$emit('polygon-click', event);
    },
    middleWaypointClick(event, index) {
      if (this.readonly) {
        return;
      }
      this.path.splice(index + 1, 0, {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
      this.$emit('before-input');
      this.$emit('input', this.path);
    },
    waypointClick(event, index) {
      this.selectedPoint = index;
      this.$refs.deleteDialog.open(index);
    },
    waypointDragstart() {
      this.$emit('before-input');
    },
    waypointDragend(event, index) {
      this.path[index].lat = event.latLng.lat();
      this.path[index].lng = event.latLng.lng();
      this.$emit('input', this.path);
    },
    waypointDrag(event, index) {
      this.$set(this.path, index, {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    },
    waypointDelete() {
      this.$emit('delete-point', this.selectedPoint);
    },
  },
};
</script>
