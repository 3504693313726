<template>
  <div class="about-body">
    <img src="/asserts/icon/about-drone-icon.svg" />
    <h2>FMS</h2>
    <p>Version: {{ config.version.describe }}</p>
    <p>API Version: {{ config.apiVersion }}</p>
    <p>CIRC &copy; 2018  {{$t('login.right_reserved')}}</p>
  </div>
</template>

<script>

export default {
  name: "AboutSubApp",
  data() {
    return {
      config: this.$config,
    };
  },
  mounted() {
    // console.log(this.$config);
  }
}
</script>

<style lang="scss" scoped>
.about-body {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 558px;
  background-color: rgba(216, 216, 216, 0.3);
  color: black;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 110px;
  text-align: center;

  img {
    margin: 8px;
  }

  h2 {
    margin-top: 8px;
    margin-bottom: 0;
    font-weight: normal;
    font-size: 18px;
  }
  p {
    color: #787878;
    font-size: 14px;
  }
}
</style>

