<template>
  <div class="sub-app location" id="accounting-index">
    <section class="app-main-toolbar">
      <el-row class="no-margin">
        <h1>{{ $t('page.setting.tab.accounting') }}</h1>
      </el-row>
      <!-- input group over 880px -->
      <div class="input-group-wrapper">
        <div class="column-1">
          <div class="date-time-picker">
            <date-time-picker
              v-model="filter.start"
              :minute-step="0"
              format-string="YYYY.MM.DD"
              :hover-show="false"
              style="padding-right: 15px"
            />
          </div>
          <div class="date-time-picker">
            <date-time-picker
              v-model="filter.end"
              :minute-step="0"
              format-string="YYYY.MM.DD"
              :hover-show="false"
              style="padding-right: 15px"
            />
          </div>
          <div class="drop-down">
            <el-select v-model="filter.user">
              <el-option
                :label="$t('page.setting.accountingTab.allUser')"
                :value="null"
              />
              <el-option
                v-for="user in userList"
                :key="user.id"
                :label="user.name"
                :value="user.id"
              />
            </el-select>
          </div>
          <div class="drop-down">
            <el-select v-model="filter.type">
              <el-option
                :label="$t('page.setting.accountingTab.typeType.all')"
                :value="null"
              />
              <el-option
                v-for="(typeName, typeKey) in typeList"
                v-if="typeKey != 'all'"
                :key="typeKey"
                :label="typeName"
                :value="typeKey"
              />
            </el-select>
          </div>

        </div>
        <div class="column-2">
          <el-button
            type="primary"
            class="main-actions"
            @click="loadData"
          >
            {{ $t('button.apply') }}
          </el-button>
        </div>
      </div>
      <div class="input-group-tablet-rwd">
        <div class="row-1">
          <div class="date-time-picker">
            <date-time-picker
              v-model="filter.start"
              class="date-time-picker-tablet"
              :minute-step="0"
              format-string="YYYY.MM.DD"
              :hover-show="false"
            />
          </div>
          <div class="date-time-picker">
            <date-time-picker
              v-model="filter.end"
              class="date-time-picker-tablet"
              :minute-step="0"
              format-string="YYYY.MM.DD"
              :hover-show="false"
            />
          </div>
        </div>
        <div class="row-2">
          <div class="drop-down">
            <el-select v-model="filter.user">
              <el-option
                :label="$t('page.setting.accountingTab.allUser')"
                :value="null"
              />
              <el-option
                v-for="user in userList"
                :key="user.id"
                :label="user.name"
                :value="user.id"
              />
            </el-select>
          </div>
          <div class="drop-down">
            <el-select v-model="filter.type">
              <el-option
                :label="$t('page.setting.accountingTab.typeType.all')"
                :value="null"
              />
              <el-option
                v-for="(typeName, typeKey) in typeList"
                v-if="typeKey != 'all'"
                :key="typeKey"
                :label="typeName"
                :value="typeKey"
              />
            </el-select>
          </div>
          <el-button
            type="primary"
            class="main-actions"
            style="width: 90%"
            @click="loadData"
          >
            {{ $t('button.apply') }}
          </el-button>
        </div>
      </div>
    </section>
    <section class="app-main-content">
      <data-table
        :data="source.items"
        :current-page="filter.page"
        :page-size="20"
        :total="source.paging.total"
        :loading="page.loading"
        :config="page.tableProps"
        :show-tool-bar="false"
        layout="table, pagination"
        :table-props="{ border: false,
                        height: &quot;calc(100% - 74px)&quot;,
                        &quot;row-class-name&quot;: &quot;coretronic&quot;,
                        &quot;header-row-class-name&quot;: &quot;coretronic table-header&quot; }"
        :pagination-props="{ background: true,
                             small: true,
                             layout: 'prev, pager, next',
                             pageSizes: [20],
                             disabled: page.loading }"
        @query-change="loadData"
      >
        <el-table-column
          v-for="columnProp in page.tableProps.tableColumnProps"
          :key="columnProp.prop"
          :type="columnProp.type"
          :label="$t(columnProp.label)"
          :column-key="columnProp.columnKey"
          :prop="columnProp.prop"
          :width="columnProp.width"
          :min-width="columnProp.minWidth"
          :sortable="columnProp.sortable"
          :resizable="columnProp.resizable"
          :formatter="columnProp.formatter"
          :align="columnProp.align"
          :header-align="columnProp.headerAlign"
          :class-name="columnProp.className"
          :label-class-name="columnProp.labelClassName"
        >
          <template scope="scope">
            <div
              v-if="columnProp.prop == 'thumbnail'"
              class="ima-cell"
            >
              <google-maps-thumbnail
                height="83px"
                :src="scope.row.thumbnail"
              />
            </div>
            <div v-else-if="columnProp.prop == 'begin_time'">
              {{ scope.row.begin_time | formatDateTime($t('formatter.dateTime')) }}
            </div>
            <div v-else-if="columnProp.prop == 'type'">
              {{ formatType(scope.row[columnProp.prop]) }}
            </div>
            <div v-else-if="columnProp.prop == 'method'">
              {{ formatAction(scope.row[columnProp.prop]) }}
            </div>
            <div v-else-if="columnProp.prop == 'status'">
              {{ formatResult(scope.row[columnProp.prop]) }}
            </div>
            <div v-else>
              {{ scope.row[columnProp.prop] }}
            </div>
          </template>
        </el-table-column>
      </data-table>
    </section>
  </div>
</template>

<script>
import { CopyObject } from '@/utils/common.js';
import { mapActions, mapGetters } from 'vuex';
import DateTimePicker from '@/components/DateTimePicker.vue';
import DefaultDialog from '@/components/DefaultDialog.vue';
import tableProps from '@/config/tableProps/accountingList';
import DataTable from '@/components/DataTable/DataTable';
import FaButton from '@/components/FaButton';
import { GroupVM } from '@/models/group';
import RecordAPI from '@/services/api/domain/record';
import UserAPI from '@/services/api/domain/user';
import GoogleMapsThumbnail from '@/components/GoogleMaps/Thumbnail.vue';
import moment from 'moment';

export default {
  name: 'AccountingSubApp',
  components: {
    DataTable,
    DefaultDialog,
    FaButton,
    GoogleMapsThumbnail,
    DateTimePicker,
  },
  data() {
    return {
      googleMapKey: this.$config.googleMapKey,
      deleteShow: false,
      source: {
        items: [],
        paging: {
          total: 0,
        },
      },
      filter: {
        page: 1,
        type: null,
        user: null,
        start: moment().set({
          hour: 0, minute: 0, second: 0, millisecond: 0,
        }),
        end: moment().set({
          hour: 23, minute: 59, second: 59, millisecond: 999,
        }),
      },
      page: {
        loading: false,
        tableProps,
      },
      userList: null,
      loadDataNonce: null,
    };
  },
  computed: {
    typeList() {
      return this.$t('page.setting.accountingTab.typeType');
    },
    ...mapGetters({
      groups: 'user/groups',
    }),
  },
  async mounted() {
    const userFetch = await UserAPI.getList();

    this.$set(this, 'userList', userFetch.data.users);
  },
  methods: {
    async loadData(filter) {
      const localNonce = this.loadDataNonce = new Object();

      if (filter && filter.page && filter.pageSize) {
        this.filter.page = filter.page;
        this.filter.limit = filter.pageSize;
      } else {
        this.filter.page = 1;
      }

      if (filter.sort != null && filter.sort.prop != null) {
        this.filter.sort = `${filter.sort.prop}${(filter.sort.order == 'descending') ? ':des' : ''}`;
      } else {
        this.filter.sort = null;
      }

      const postFilter = CopyObject(this.filter);
      postFilter.start = this.filter.start.toISOString();
      postFilter.end = this.filter.end.toISOString();

      try {
        this.page.loading = true;
        const { meta, data } = await RecordAPI.getList(postFilter);
        if (localNonce !== this.loadDataNonce) {
          // a new call was made
          return;
        }
        this.source.items = data.records;
        this.source.paging.total = data.total;
        this.page.loading = false;
      } catch (error) {
        this.$showFail(error);
        this.page.loading = false;
      // } finally {
      //   this.page.loading = false;
      }
    },
    formatType(type) {
      switch (type) {
        case 'login':
        case 'users':
        case 'groups':
        case 'missions':
        case 'noflyzones':
        case 'histories':
        case 'router_plan':
        case 'trigger_events':
        case 'drones':
        case 'nests':
        case 'cameras':
        case 'weather_stations':
        case 'triggers':
        case 'forwards':
          return this.$t(`page.setting.accountingTab.typeType.${type}`);
        default:
          return type;
      }
    },
    formatAction(action) {
      switch (action) {
        case 'GET':
        case 'POST':
        case 'PUT':
        case 'DELETE':
          return this.$t(`page.setting.accountingTab.actionType.${action}`);
        default:
          return action;
      }
    },
    formatResult(result) {
      if (result == 200) {
        return this.$t('page.setting.accountingTab.resultType.success');
      }
      return this.$t('page.setting.accountingTab.resultType.fail');
    },
  },
};
</script>

<style lang="scss">
.app-main-toolbar .el-row h1 {
  margin-bottom: 21px;
}
.accounting-filter-option {
  > * {
    display: inline-block;
  }

  .drop-down {
    margin-left: 22px;
    width: 132px;
  }
}

#accounting-index {
  .el-select {
    width: 100%;
  }

  .drop-down {
    padding-right: 15px;
  }

  .input-group-wrapper {
    display: flex;
    margin-top: 10px;
    @include tablet-medium-880px {
      display: none;
    }
    .column-1 {
      display: flex;
      width: 85%;
    }

    .column-2 {
      display: flex;
      flex-direction: row-reverse;
      width: 15%;
    }
  }
  .input-group-tablet-rwd {
    display: none;
    @include tablet-medium-880px {
      display: flex;
      flex-direction: column;
      .row-1 {
        @include justify-content-between;
        margin-top: 10px;
        .date-time-picker {
          width: 48.6%;
        }
      }

      .row-2 {
        @include justify-content-between;
        margin-top: 13px;
        .drop-down, button {
          width: 100%
        }
      }

    }
  }
}
</style>
