<template>
  <div v-if="zoneSource">
    <!-- TODO: 這裡的 z-index 無效，PolygonEditor 的頂點跟線條還是會被 NoFlyZone 壓住 -->
    <vl-layer-vector :id="`PolygonEditorMainLayer-${zone.id}`" 
                     :z-index="zIndex"
    >
      <vl-style-func :factory="polygonAndVerticesStyles" />
      <vl-source-vector :ident="`PolygonEditorSource-${zone.id}`"
                        :features.sync="zoneSource"
                        ref="polygonSource">
      </vl-source-vector>
    </vl-layer-vector>

    <!-- polygon and vertices -->
    <!-- ol/interaction/Translate 看起來會更像 Google Maps -->
    <vl-interaction-modify v-if="!readonly"
                           :source="`PolygonEditorSource-${zone.id}`"
                           :insertVertexCondition="insertVertexCondition"
                           @modifystart="beforeInput"
                           @modifyend="modifyend"
                           ref="modify"
    >
      <vl-style-box>
      </vl-style-box>    </vl-interaction-modify>
    <!-- middle point -->
    <vl-layer-vector v-if="!readonly"
                     :id="`PolygonEditorMiddleLayer-${zone.id}`"
                     :z-index="zIndex + 1"
    >
      <vl-feature v-for="(point, index) in middlePoints"
                  :key="`PolygonEditorMiddlePointFeature-${zone.id}-${index}`"
                  :id="`PolygonEditorMiddlePointFeature-${zone.id}-${index}`"
                  :name="`PolygonEditorMiddlePointFeature-${zone.name}-${index}`"
                  :properties="{
                    type: 'middlePoint',
                    source: `${zoneType}`,
                    id: zone.id,
                    name: zone.name,
                    sequence: index,
                  }"
      >
        <vl-geom-point :coordinates="point" />
        <vl-style-box>
          <vl-style-icon v-bind="middleMarkerIconParams" />
        </vl-style-box>
      </vl-feature>

    </vl-layer-vector>

  </div>
</template>

<script>
import config from 'config';
import { CopyObject } from '@/utils/common.js';
import { GoogleMapsMarkerToOl } from '@/utils/map.js';
import { WaypointStyle, WaypointNormalStyle, WaypointSelectedStyle } from '@/components/GoogleMaps/MarkerStyle.js';

import { never } from 'ol/events/condition'
import MultiPoint from 'ol/geom/MultiPoint.js';
import {Circle as CircleStyle, Fill, Stroke, Style, Icon, RegularShape} from 'ol/style.js';
import { transform as ProjectTransform } from 'ol/proj';


export default {
  name: 'PolygonEditor',
  components: {

  },
  data() {
    return {
      components: {},
      style: {},
      insertVertexCondition: never,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    polygonAndVerticesStyles() {
      return feature => {
        return [
          // polygon
          new Style({
            stroke: new Stroke({
              color: this.style.strokeColor,
              width: this.style.strokeWidth
            }),
            fill: new Fill({
              color: this.style.fillColor
            })
          }),
          // vertices
          new Style({
            image: new Icon(GoogleMapsMarkerToOl(this.markerIcon)),
            geometry: function(feature) {
              // return the coordinates of the first ring of the polygon
              var coordinates = feature.getGeometry().getCoordinates()[0];
              return new MultiPoint(coordinates);
            }
          }),
        ];
      }
    },
    beforeInput() {
      this.$emit('before-input');
    },
    modifyend(event) {
      let zone = {
        ...this.zone
      }
      let eventFeature = event.features.getArray()[0];
      let geometryCoordinates = eventFeature.getGeometry().getCoordinates();
      let coordinates = geometryCoordinates.map(geometryCoordinate => {
        return geometryCoordinate.map(coordinate => {
          return ProjectTransform(coordinate, config.openlayers.olProjection, config.openlayers.vlProjection );
        });
      });
      // zoneSource 更新速度並不快，很容易發生滑鼠按鍵放開當 source 還沒更新的造成彈回
      // zone.coordinates = this.zoneSource[0].geometry.coordinates;
      zone.coordinates = coordinates;
      this.$emit('update:zone', zone);
    },
  },
  computed: {
    zoneSource: {
      get() {
        if (this.zone == null ||
          this.zone.coordinates.length === 0 ||
          this.zone.coordinates[0] == null) {
            return null;
        }

        this.style = this.polygonComponent.data();
        return [{
          geometry: {
            coordinates: CopyObject(this.zone.coordinates),
            type: 'Polygon',
          },
          id: `PolygonEditorPolygonFeature-${this.zone.id}`,
          properties: {
            type: 'polygon',
            source: this.zoneType,
            id: this.zone.id,
            name: this.zone.name,
          },
          type: 'Feature',
        }];
      },
      set(value) {
        // 依然有更新太慢的問題，這邊不更新 zone 屬性
      },
    },
    middlePoints() {
      let middlePoints = [];
      if (this.zoneSource == null || 
        this.zoneSource[0] == null || 
        this.zoneSource[0].geometry == null ||
        this.zoneSource[0].geometry.coordinates == null ||
        this.zoneSource[0].geometry.coordinates[0] == null) {
          return middlePoints;
      }

      if (this.zoneSource[0].geometry.coordinates[0].length === 2) {
        return middlePoints;
      }

      for (let index in this.zoneSource[0].geometry.coordinates[0]) {
        let point = this.zoneSource[0].geometry.coordinates[0][index];
        let nextPoint = this.zoneSource[0].geometry.coordinates[0][parseInt(index) + 1];

        if (nextPoint == null) {
          continue;
        }

        middlePoints.push([
          (point[0] + nextPoint[0]) / 2,
          (point[1] + nextPoint[1]) / 2,
        ]);
      }
      return middlePoints;
    },
    middleMarkerIconParams() {
      return GoogleMapsMarkerToOl(WaypointStyle.middleWaypoint);
    },
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    markerIcon: {
      type: Object,
      default() {
        return WaypointStyle.fenceWaypoint
      },
    },
    polygonComponent: {
      type: Object,
      required: true,
    },
    zone: {
      type: Object,
      required: true,
    },
    zoneType: {
      type: String,
      required: true,
    },
    zIndex: {
      type: Number,
      default: 10
    },
  },
}
</script>