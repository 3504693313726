export default [
  {
    name: 'BingMaps',
    class: 'BingMaps',
    component: 'vl-source-bingmaps',
    document: 'https://openlayers.org/en/latest/apidoc/module-ol_source_BingMaps-BingMaps.html',
    options: [
      {
        key: 'cacheSize',
        type: 'number',
        inputType: 'number',
      },
      {
        key: 'hidpi',
        type: 'boolean',
        inputType: 'checkbox',
      },
      {
        key: 'culture',
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'apiKey',
        required: true,
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'imagerySet',
        required: true,
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'maxZoom',
        type: 'number',
        inputType: 'number',
      },
      // {
      //   key: 'reprojectionErrorThreshold',
      //   type: 'number',
      //   inputType: 'number',
      // },
      // {
      //   key: 'tileLoadFunction',
      //   type: 'function',
      //   inputType: 'textarea',
      // },
      {
        key: 'wrapX',
        type: 'boolean',
        inputType: 'checkbox',
      },
      {
        key: 'transition',
        type: 'number',
        inputType: 'number',
      },
    ],
  },
  // {
  //   name: 'ImageStatic',
  //   class: 'ImageStatic',
  //   component: 'vl-source-image-static',
  //   document: 'https://openlayers.org/en/latest/apidoc/module-ol_source_ImageStatic-Static.html',
  //   options: [
  //     {
  //       key: 'attributions',
  //       type: 'string',
  //       inputType: 'string',
  //     },
  //     {
  //       key: 'crossOrigin',
  //       type: 'number',
  //       inputType: 'number',
  //     },
  //     {
  //       key: 'imageExtent',
  //       type: 'object',
  //       inputType: 'textarea',
  //     },
  //     {
  //       key: 'imageLoadFunction',
  //       type: 'function',
  //       inputType: 'textarea',
  //     },
  //     {
  //       key: 'projection',
  //       type: 'string',
  //       inputType: 'string',
  //     },
  //     {
  //       key: 'imageSize',
  //       type: 'object',
  //       inputType: 'textarea',
  //     },
  //     {
  //       key: 'url',
  //       type: 'string',
  //       inputType: 'string',
  //     },
  //   ],
  // },
  {
    name: 'ImageWMS',
    class: 'ImageWMS',
    component: 'vl-source-image-wms',
    document: 'https://openlayers.org/en/latest/apidoc/module-ol_source_ImageWMS-ImageWMS.html',
    options: [
      {
        key: 'attributions',
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'crossOrigin',
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'hidpi',
        type: 'boolean',
        inputType: 'checkbox',
      },
      {
        key: 'serverType',
        type: 'string',
        inputType: 'string',
      },
      // {
      //   key: 'imageLoadFunction',
      //   type: 'function',
      //   inputType: 'textarea',
      // },
      {
        key: 'params',
        required: true,
        type: 'object',
        inputType: 'textarea',
      },
      {
        key: 'projection',
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'ratio',
        type: 'number',
        inputType: 'number',
      },
      {
        key: 'resolutions',
        type: 'number',
        inputType: 'number',
      },
      {
        key: 'url',
        required: true,
        type: 'string',
        inputType: 'string',
      },
    ],
  },
  {
    name: 'OpenStreetMap',
    class: 'OSM',
    component: 'vl-source-osm',
    document: 'https://openlayers.org/en/latest/apidoc/module-ol_source_OSM-OSM.html',
    options: [
      {
        key: 'attributions',
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'cacheSize',
        type: 'number',
        inputType: 'number',
      },
      {
        key: 'crossOrigin',
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'maxZoom',
        type: 'number',
        inputType: 'number',
      },
      {
        key: 'opaque',
        type: 'boolean',
        inputType: 'checkbox',
      },
      // {
      //   key: 'reprojectionErrorThreshold',
      //   type: 'number',
      //   inputType: 'number',
      // },
      // {
      //   key: 'tileLoadFunction',
      //   type: 'function',
      //   inputType: 'textarea',
      // },
      {
        key: 'url',
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'wrapX',
        type: 'boolean',
        inputType: 'checkbox',
      },
      {
        key: 'transition',
        type: 'number',
        inputType: 'number',
      },
    ],
  },
  {
    name: 'Stamen',
    class: 'Stamen',
    component: 'vl-source-stamen',
    document: 'https://openlayers.org/en/latest/apidoc/module-ol_source_Stamen-Stamen.html',
    options: [
      {
        key: 'cacheSize',
        type: 'number',
        inputType: 'number',
      },
      {
        key: 'layer',
        required: true,
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'minZoom',
        type: 'number',
        inputType: 'number',
      },
      {
        key: 'maxZoom',
        type: 'number',
        inputType: 'number',
      },
      {
        key: 'opaque',
        type: 'boolean',
        inputType: 'checkbox',
      },
      // {
      //   key: 'reprojectionErrorThreshold',
      //   type: 'number',
      //   inputType: 'number',
      // },
      // {
      //   key: 'tileLoadFunction',
      //   type: 'function',
      //   inputType: 'textarea',
      // },
      // {
      //   key: 'transition',
      //   type: 'number',
      //   inputType: 'number',
      // },
      {
        key: 'url',
        required: true,
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'wrapX',
        type: 'boolean',
        inputType: 'checkbox',
      },
    ],
  },
  {
    name: 'TileArcGISRest',
    class: 'TileArcGISRest',
    component: 'vl-source-arcgis-rest',
    document: 'https://openlayers.org/en/latest/apidoc/module-ol_source_TileArcGISRest-TileArcGISRest.html',
    options: [
      {
        key: 'attributions',
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'cacheSize',
        type: 'number',
        inputType: 'number',
      },
      {
        key: 'crossOrigin',
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'params',
        type: 'object',
        inputType: 'textarea',
      },
      // {
      //   key: 'hidpi',
      //   type: 'boolean',
      //   inputType: 'checkbox',
      // },
      {
        key: 'tileGrid',
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'projection',
        type: 'string',
        inputType: 'string',
      },
      // {
      //   key: 'reprojectionErrorThreshold',
      //   type: 'number',
      //   inputType: 'number',
      // },
      // {
      //   key: 'tileLoadFunction',
      //   type: 'function',
      //   inputType: 'textarea',
      // },
      {
        key: 'url',
        required: true,
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'wrapX',
        type: 'boolean',
        inputType: 'checkbox',
      },
      {
        key: 'transition',
        type: 'number',
        inputType: 'number',
      },
      // {
      //   key: 'urls',
      //   type: 'array',
      //   inputType: 'textarea',
      // },
    ],
  },
  {
    name: 'TileWMS',
    class: 'TileWMS',
    component: 'vl-source-wms',
    document: 'https://openlayers.org/en/latest/apidoc/module-ol_source_TileWMS-TileWMS.html',
    options: [
      {
        key: 'attributions',
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'cacheSize',
        type: 'number',
        inputType: 'number',
      },
      {
        key: 'crossOrigin',
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'params',
        required: true,
        type: 'object',
        inputType: 'textarea',
      },
      {
        key: 'gutter',
        type: 'number',
        inputType: 'number',
      },
      {
        key: 'hidpi',
        type: 'boolean',
        inputType: 'checkbox',
      },
      {
        key: 'projection',
        type: 'string',
        inputType: 'string',
      },
      // {
      //   key: 'reprojectionErrorThreshold',
      //   type: 'number',
      //   inputType: 'number',
      // },
      // {
      //   key: 'tileClass',
      //   type: 'object',
      //   inputType: 'textarea',
      // },
      {
        key: 'tileGrid',
        type: 'object',
        inputType: 'textarea',
      },
      {
        key: 'serverType',
        type: 'string',
        inputType: 'string',
      },
      // {
      //   key: 'tileLoadFunction',
      //   type: 'function',
      //   inputType: 'textarea',
      // },
      {
        key: 'url',
        required: true,
        type: 'string',
        inputType: 'string',
      },
      // {
      //   key: 'urls',
      //   type: 'array',
      //   inputType: 'textarea',
      // },
      {
        key: 'wrapX',
        type: 'boolean',
        inputType: 'checkbox',
      },
      {
        key: 'transition',
        type: 'number',
        inputType: 'number',
      },
    ],
  },
  {
    name: 'WMTS',
    class: 'WMTS',
    component: 'vl-source-wmts',
    document: 'https://openlayers.org/en/latest/apidoc/module-ol_source_WMTS-WMTS.html',
    options: [
      {
        key: 'attributions',
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'cacheSize',
        type: 'number',
        inputType: 'number',
      },
      {
        key: 'crossOrigin',
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'tileGrid',
        type: 'object',
        inputType: 'textarea',
      },
      {
        key: 'projection',
        type: 'string',
        inputType: 'string',
      },
      // {
      //   key: 'reprojectionErrorThreshold',
      //   type: 'number',
      //   inputType: 'number',
      // },
      {
        key: 'requestEncoding',
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'layerName',
        required: true,
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'styleName',
        type: 'string',
        inputType: 'string',
      },
      // {
      //   key: 'tileClass',
      //   type: 'object',
      //   inputType: 'textarea',
      // },
      {
        key: 'tilePixelRatio',
        type: 'number',
        inputType: 'number',
      },
      {
        key: 'format',
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'version',
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'matrixSet',
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'dimensions',
        type: 'object',
        inputType: 'textarea',
      },
      {
        key: 'url',
        required: true,
        type: 'string',
        inputType: 'string',
      },
      // {
      //   key: 'tileLoadFunction',
      //   type: 'function',
      //   inputType: 'textarea',
      // },
      // {
      //   key: 'urls',
      //   type: 'array',
      //   inputType: 'textarea',
      // },
      {
        key: 'wrapX',
        type: 'boolean',
        inputType: 'checkbox',
      },
      {
        key: 'transition',
        type: 'number',
        inputType: 'number',
      },
    ],
  },
  {
    name: 'XYZ',
    class: 'XYZ',
    component: 'vl-source-xyz',
    document: 'https://openlayers.org/en/latest/apidoc/module-ol_source_XYZ-XYZ.html',
    options: [
      {
        key: 'attributions',
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'attributionsCollapsible',
        type: 'boolean',
        inputType: 'checkbox',
      },
      {
        key: 'cacheSize',
        type: 'number',
        inputType: 'number',
      },
      {
        key: 'crossOrigin',
        type: 'string',
        inputType: 'string',
      },
      {
        key: 'opaque',
        type: 'boolean',
        inputType: 'checkbox',
      },
      {
        key: 'projection',
        type: 'string',
        inputType: 'string',
      },
      // {
      //   key: 'reprojectionErrorThreshold',
      //   type: 'number',
      //   inputType: 'number',
      // },
      {
        key: 'maxZoom',
        type: 'number',
        inputType: 'number',
      },
      {
        key: 'minZoom',
        type: 'number',
        inputType: 'number',
      },
      {
        key: 'tileGrid',
        type: 'string',
        inputType: 'string',
      },
      // tileLoadFunction: {
      //   key: '',
      //   type: 'function',
      //   inputType: 'textarea',
      // },
      {
        key: 'tilePixelRatio',
        type: 'number',
        inputType: 'number',
      },
      // {
      //   key: 'tileSize',
      //   type: 'number',
      //   inputType: 'number',
      // },
      // tileUrlFunction: {
      //   key: '',
      //   type: 'function',
      //   inputType: 'textarea',
      // },
      {
        key: 'url',
        required: true,
        type: 'string',
        inputType: 'string',
      },
      // {
      //   key: 'urls',
      //   type: 'array',
      //   inputType: 'textarea',
      // },
      {
        key: 'wrapX',
        type: 'boolean',
        inputType: 'checkbox',
      },
      {
        key: 'transition',
        type: 'number',
        inputType: 'number',
      },
      // {
      //   key: 'zDirection',
      //   type: 'number',
      //   inputType: 'number',
      // },
    ],
  },
];
