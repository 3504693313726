<template>
  <el-popover ref="popover"
              placement="top"
              trigger="click"
              width="427"
              popper-class="url-popover"
              :title="title"
              :content="url"
  >
    <div class="popover-close"
         v-on:click="close"
    >
      <img src="/asserts/icon/devices-url-close-icon.svg">
    </div>
    <div class="popover-url">
      <div class="popover-text">
        <textarea ref="textArea"
                  class="hidden-text-area"
                  rows="3"
                  :value="url"
                  readonly
        ></textarea>
      </div>
      <div class="popover-copy">
        <el-button type="primary" 
                    size="mini"
                    @click="copyToClip()"
        > {{ $t('button.copy') }}</el-button>
      </div>
    </div>
    <slot slot="reference"/>
  </el-popover>
</template>

<script>
import FaButton from '@/components/FaButton';

export default {
  name: 'CopyUrl',
  components: {
    FaButton,
  },
  props: {
    title: {
      required: false,
      type: String,
      default() {
        return this.$t('button.url');
      }
    },
    url: {
      required: true,
      type: String,
    },
  },
  methods: {
    copyToClip() {
      let textArea = this.$refs.textArea;
      textArea.select();
      document.execCommand('copy');
    },
    close() {
      // ref: https://github.com/ElemeFE/element/blob/dev/packages/popover/src/main.vue#L141
      this.$refs.popover.doClose();
    },
  },
}
</script>

<style lang="scss">
.url-popover {
  padding: 20px 32px;

  .popover-close {
    color: #4d4d4d;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    right: 14px;
    top: 14px;

    img {
      display: block;
    }
  }

  .el-popover__title {
    font-size: 14px;
    font-weight: 500;
  }
  .popover-url {
    display: flex;
    min-height: 20px;
    .popover-text {
      flex-grow: 1;
      word-break: break-all;
      padding-right: 15px;
      .hidden-text-area {
        border: 0;
        width: 100%;
        resize: none;
        padding: 0;
        font-size: 12px;
        color: rgba(60, 60, 60, 0.9);
      }
    }

    .popover-copy {
      flex-grow: 0;
      .el-button--mini {
        width: 76px;
        height: 24px;
      }
    }
  }
}

</style>
