<template>
  <div>
    <default-google-map
      ref="map"
      :center="map.center"
      :zoom="zoom"
      :map-type-id="map.typeId"
      @click="e => mapClick(e, { source: 'map' })"
      @bounds_changed="updateRange"
      @idle="onIdle"
      @tilesloaded="tilesLoaded"
      @zoom_changed="
        event => {
          $emit('update:zoom', event);
        }
      "
    >
      <fly-area
        v-if="fence"
        :path="map.fence"
        @click="e => mapClick(e, { type: 'polygon', source: 'fence', id: 'fence' })"
      />
      <template v-for="(zone, index) in map.noFlyZone">
        <polygon-editor
          v-show="editZone == null || zone.id != editZone.id"
          :key="`noFlyZone-${index}`"
          v-model="zone.polygons[0]"
          :readonly="true"
          :index="index"
          :polygon-component="formatNoFlyZoneTypeToComponent(zone.type)"
          @polygon-click="e => mapClick(e, { type: 'polygon', source: 'no_fly_zones', id: zone.id })"
        />
      </template>
      <template
        v-if="editZone != null"
        class="editor"
      >
        <zone-dialog
          :value="editZone"
          :editable="editZone.level==0"
          @input="updateData"
          @close="e => $emit('zone-close')"
          @delete="e => $emit('zone-delete')"
          @save="e => $emit('zone-save')"
        />
        <polygon-editor
          ref="zones"
          :value="editZone.polygons[0]"
          :readonly="editZone.level!=0"
          :polygon-component="formatNoFlyZoneTypeToComponent(editZone.type)"
          @input="inputPolygon"
          @delete-point="deletePoint"
        />
      </template>
    </default-google-map>
    <operator
      :menu-item="['map-mode', 'gps', 'import', 'clear']"
      @click="o => $emit('operator', o)"
    />
    <zoom-button
      @zoomin="$emit('update:zoom', zoom + 1)"
      @zoomout="$emit('update:zoom', zoom - 1)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CopyObject } from '@/utils/common.js';
import {
  formatNoFlyZoneTypeToComponent, calculBounds, GeographicShorter, FormatPolygonsToLatLng,
} from '@/utils/map.js';

import Operator from '@/components/Maps/Operator.vue';
import ZoomButton from '@/components/Maps/ZoomButton.vue';

import DefaultGoogleMap from '@/components/GoogleMaps/DefaultGoogleMap.js';
import PolygonEditor from '@/components/GoogleMaps/PolygonEditor.vue';
import ZoneDialog from '@/components/Maps/ZoneDialog.vue';
import FlyArea from '@/components/GoogleMaps/FlyArea.js';

export default {
  name: 'NoFLyZoneMapGoogleMaps',
  components: {
    DefaultGoogleMap,
    Operator,
    ZoomButton,
    FlyArea,
    PolygonEditor,
    ZoneDialog,
  },
  props: {
    typeCount: {
      type: Number,
      default: 0,
    },
    center: {
      type: Object,
      required: true,
    },
    zoom: {
      type: Number,
      required: true,
    },
    fence: {
      type: Array,
      required: false,
      default: () => [],
    },
    noFlyZone: {
      type: Array,
      required: false,
      default: () => [],
    },
    mapRange: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      map: {
        center: { lat: 0, lng: 0 },
        typeId: 'satellite',
        fence: [],
        noFlyZone: [],
      },
      editZoneData: null,
      lastMapRange: [],
      tempBound: [],
      lastLoading: 0,
    };
  },
  computed: {
    ...mapGetters({
      editZone: 'noFlyZone/getNoFlyZoneGoogle',
    }),
  },
  watch: {
    lastMapRange(now, old) {
      if (_.isEqual(now, old)) {
        return;
      }

      this.$emit('update:mapRange', now);
    },
    center(now) {
      // using two-way bind will hang map when you dragging
      // @center_changed="(event) => {this.$emit('update:center',event)}"

      this.$refs.map.panTo(GeographicShorter(now));
      this.$emit('update:centerRead', this.center);
    },
    typeCount(now) {
      const set = now % 2;
      switch (set) {
        case 0:
          this.map.typeId = 'satellite';
          break;
        case 1:
          this.map.typeId = 'roadmap';
          break;
        default:
          break;
      }
    },
    noFlyZone() {
      this.loadNoFlyZone();
    },
  },
  mounted() {
    this.map.center = GeographicShorter(this.center);
    this.map.fence = this.fence.map(GeographicShorter);
    this.loadNoFlyZone();
  },
  methods: {
    mapClick(event, source) {
      const position = {
        latitude: event.latLng.lat(),
        longitude: event.latLng.lng(),
      };
      const clickEvent = {
        position,
        ...source,
      };

      this.$emit('map-click', clickEvent);
    },
    centerChange(center) {
      if (typeof center.lat === 'function') {
        center = {
          lat: center.lat(),
          lng: center.lng(),
        };
      }
      this.$emit('update:centerRead', GeographicLonger(center));
    },
    onIdle() {
      if (this.lastLoading === 0) {
        const boundcalculBounds = calculBounds(this.map.fence);
        this.$refs.map.fitBounds(boundcalculBounds);
      }
      this.lastLoading += 1;
      const now = this.lastLoading;
      setTimeout(() => {
        if (now === this.lastLoading) {
          this.lastMapRange = this.tempBound;
          if (this.lastLoading > 9999) {
            this.lastLoading = 1;
          }
        }
      }, 1000);
    },
    updateRange(bounds) {
      if (bounds == null) {
        return;
      }
      const northEast = bounds.getNorthEast();
      const southWest = bounds.getSouthWest();
      const round = 1E6;
      this.tempBound = [
        Math.floor(northEast.lat() * round) / round,
        Math.floor(northEast.lng() * round) / round,
        Math.ceil(southWest.lat() * round) / round,
        Math.ceil(southWest.lng() * round) / round,
      ];
    },
    loadNoFlyZone() {
      let data = CopyObject(this.noFlyZone);
      data = data.map((zone) => {
        zone.polygons = FormatPolygonsToLatLng(zone.polygons);
        return zone;
      });
      this.map.noFlyZone = data;
    },
    inputPolygon(polygon) {
      const data = { ...this.editZone };
      data.polygons[0] = polygon;
      this.updateData(data);
    },
    formatNoFlyZoneTypeToComponent,
    ...mapActions({
      updateData: 'noFlyZone/loadFromGoogle',
    }),
    tilesLoaded() {
      const { fence } = this.map;
      if (this.tileLoaded == undefined && fence && fence.length > 0) {
        const boundcalculBounds = calculBounds(fence);
        this.$refs.map.fitBounds(boundcalculBounds);
      }
      this.tileLoaded = true;
    },
    deletePoint(point) {
      this.$emit('delete-point', point);
    },
  },
};
</script>
