export default {
  tableColumnProps: [
    {
      label: 'page.setting.accountingTab.date',
      prop: 'begin_time',
      width: '190px',
      minWidth: null,
      sortable: 'custom',
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.setting.accountingTab.user',
      type: null,
      columnKey: null,
      prop: 'updater_name',
      width: null,
      minWidth: '140px',
      sortable: null,
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.setting.accountingTab.type',
      type: null,
      columnKey: null,
      prop: 'type',
      width: null,
      minWidth: '120px',
      sortable: 'custom',
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.setting.accountingTab.action',
      type: null,
      columnKey: null,
      prop: 'method',
      width: null,
      minWidth: '180px',
      sortable: 'custom',
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
    {
      label: 'page.setting.accountingTab.result',
      type: null,
      columnKey: null,
      prop: 'status',
      width: null,
      minWidth: '110px',
      sortable: 'custom',
      resizable: null,
      searchable: true,
      formatter: null,
      align: null,
      headerAlign: null,
      className: null,
      labelClassName: null,
      fixed: null,
      defaultSort: null,
    },
  ],
};