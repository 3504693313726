<template>
  <div class="sub-fillall-app">
    <component
      :is="mapImplement"
      :type-count.sync="typeCount"
      :center="center"
      :center-read.sync="centerReads"
      :zoom.sync="map.zoom"
      :map-range.sync="mapRange"
      :fence="map.fence"
      :no-fly-zone="map.noFlyZone"
      @map-click="mapClick"
      @zone-close="zoneClose"
      @zone-delete="zoneDelete"
      @zone-save="zoneSave"
      @operator="operator"
      @delete-point="deletePoint"
    />
    <search-bar @selectPlace="selectPlace" />
    <type-selector
      v-model="mapEditType"
      :items="noFlyZoneType"
      @input="changeType"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GPSGetCurrentPosition, formatNoFlyZoneTypeToComponent } from '@/utils/map.js';

import SearchBar from '@/components/Maps/SearchBar.vue';
import TypeSelector from '@/components/Maps/TypeSelector.vue';

import GoogleMapsImplement from './NoFlyZone-GoogleMaps.vue';
import OpenLayersImplement from './NoFlyZone-OpenLayers.vue';

import NoFlyZoneAPI from '@/services/api/domain/noFlyZone.js';

export default {
  name: 'NoFLyZoneMap',
  components: {
    SearchBar,
    TypeSelector,
  },
  data() {
    return {
      mapImplement: null,
      typeCount: 0,
      noFlyZoneType: ['no_fly', 'altitude_limit', 'obstacle'],
      map: {
        zoom: 17,
        range: [],
        noFlyZone: [],
        fence: [],
      },
      mapEditType: 'no_fly',
      mapRange: [],
      center: {},
      centerReads: {},
      lastLoading: 0,
    };
  },
  computed: {
    ...mapGetters({
      group: 'user/group',
      mapEngine: 'user/mapEngine',
      domainCenter: 'user/location',
      editZone: 'noFlyZone/getNoFlyZone',
    }),
  },
  watch: {
    async mapRange(now, old) {
      if (_.isEqual(now, old)) {
        return;
      }
      this.fetchZones();
    },
  },
  created() {
    if (this.group.latitude != null && this.group.longitude) {
      this.$set(this, 'center', {
        latitude: this.group.latitude,
        longitude: this.group.longitude,
      });
    } else {
      this.$set(this, 'center', this.domainCenter);
    }
    this.mapReset();
    this.$set(this.map, 'fence', this.group.fence);
  },
  async mounted() {
    switch (this.mapEngine) {
      case 'GoogleMaps':
        this.mapImplement = GoogleMapsImplement;
        break;
      case 'OpenLayers':
        this.mapImplement = OpenLayersImplement;
        break;
    }
  },
  methods: {
    async fetchZones() {
      try {
        const { data } = await NoFlyZoneAPI.getList(this.mapRange);
        this.map.noFlyZone = data.no_fly_zones;
      } catch (error) {
        this.$showFail(error);
      }
    },
    zoneClose() {
      this.closeZone();
    },
    async zoneSave() {
      const loading = this.$loading({
        lock: true,
      });
      try {
        if (this.editZone.id == null) {
          await NoFlyZoneAPI.create(this.editZone);
        } else {
          await NoFlyZoneAPI.update(this.editZone.id, this.editZone);
        }
        this.fetchZones();
      } catch (error) {
        this.$showFail(error);
      }
      loading.close();
      this.closeZone();
    },
    mapClick(event) {
      switch (event.source) {
        case 'no_fly_zones':
          const editZone = this.map.noFlyZone.find((zone) => zone.id == event.id);
          this.loadZone(editZone);
          this.mapEditType = editZone.type;
          break;
        default:
          if (this.editZone == null) {
            this.newZone();
            this.setZoneType(this.mapEditType);
          }
          if (this.editZone.level == 0) {
            this.insert(event);
          }
          break;
      }
    },
    deletePoint(event) {
      this.delete(event);
    },
    async zoneDelete() {
      const loading = this.$loading({
        lock: true,
      });

      try {
        if (this.editZone.id != null) {
          await NoFlyZoneAPI.delete(this.editZone.id);
        }
        this.fetchZones();
      } catch (error) {
        this.$showFail(error);
      }

      loading.close();
      this.zoneClose();
    },
    changeType(type) {
      this.setZoneType(type);
    },
    operator(name) {
      switch (name) {
        case 'map-mode':
          this.typeCount += 1;
          break;
        case 'gps':
          this.gps();
          break;
        case 'import':
          this.importNoFlyZone();
          break;
        case 'clear':
          this.deleteImportedNoFlyZone();
          break;
        default:
          this.$emit('operator', name);
      }
    },
    async gps() {
      try {
        this.$showInfo('notify.gettingGeolocation');
        const position = await GPSGetCurrentPosition();
        const coord = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        this.$set(this, 'center', coord);
        this.mapPanTo(coord);
        this.map.zoom = 17;
      } catch (error) {
        console.error(error);
        if (error == null) {
          this.$showFail(this.$t('errorMsg.browserUnsupport'));
          return;
        }
        if (error.code == 1) {
          if (!window.isSecureContext) {
            this.$showFail(this.$t('errorMsg.requireSecureConnection'));
          } else {
            this.$showFail(this.$t('errorMsg.requireGeolocationPermission'));
          }
        } else {
          this.$showFail(this.$t('errorMsg.getLocationFailed'));
        }
      }
    },
    async importNoFlyZone() {
      const loading = this.$loading({
        lock: true,
      });
      try {
        await NoFlyZoneAPI.importWithBoundary(this.mapRange);
        this.fetchZones();
      } catch (error) {
        this.$showFail(error);
      }
      loading.close();
      this.zoneClose();
    },
    async deleteImportedNoFlyZone() {
      const loading = this.$loading({
        lock: true,
      });
      try {
        await NoFlyZoneAPI.clearWithBoundary(this.mapRange);
        this.fetchZones();
      } catch (error) {
        this.$showFail(error);
      }
      loading.close();
      this.zoneClose();
    },
    selectPlace(place) {
      this.mapFitBounds(place.bounds);
    },
    formatNoFlyZoneTypeToComponent,
    ...mapActions({
      newZone: 'noFlyZone/new',
      loadZone: 'noFlyZone/load',
      closeZone: 'noFlyZone/close',
      saveZoneToAPI: 'noFlyZone/save',
      deleteZoneToAPI: 'noFlyZone/delete',
      setZoneType: 'noFlyZone/setType',
      insert: 'noFlyZone/insert',
      delete: 'noFlyZone/delete',
      mapReset: 'map/reset',
      mapPanTo: 'map/panTo',
      mapFitBounds: 'map/fitBounds',
    }),
  },
};
</script>

<style lang="scss"></style>
